import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError as globalSetError } from '@/store/setError';

const {
  deleteAgent,
  putAgentOperators,
  putAgentRoles,
  postAgentFromUser,
} = backOffice.agent;

export const AGENT_SCOPES = {
  form: 'form',
  deleteAgent: 'deleteAgent',
};

const agents = createCustomStore(({ runAsyncFlow }) => withScopes(AGENT_SCOPES, ({
  state: {
    [AGENT_SCOPES.form]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      if (scope === AGENT_SCOPES.deleteAgent) {
        globalSetError.bind(this)(state, payload);
      } else {
        state[scope].error = value;
      }
    },
    resetData(state, { scope }) {
      state[scope].data = null;
    },
    clearErrors(state, { scope }) {
      state[scope].error = null;
      state[scope].STATUS = { ...INITIAL_STATUS };
    },
  },
  actions: {
    async deleteAgent({ commit }, agentUuid) {
      await runAsyncFlow(commit, {
        request: deleteAgent,
        params: [agentUuid],
        scope: AGENT_SCOPES.deleteAgent,
      });
    },
    async postAgentFromUser({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postAgentFromUser,
        params: [data],
        scope: AGENT_SCOPES.form,
      });
    },
    async putAgentOperators({ commit }, { agentUuid, data }) {
      await runAsyncFlow(commit, {
        request: putAgentOperators,
        params: [agentUuid, data],
        scope: AGENT_SCOPES.form,
      });
    },
    async putAgentRoles({ commit }, { agentUuid, data }) {
      await runAsyncFlow(commit, {
        request: putAgentRoles,
        params: [agentUuid, data],
        scope: AGENT_SCOPES.form,
      });
    },
  },
})));

export default agents;
