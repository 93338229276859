import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors, toastError } from '@/utils/store';

const {
  getOperatorConfigCategories,
  getOperatorConfigSettingsByCategory,
  putOperatorConfigValues,
} = backOffice.operatorConfig;

const { postImportOperator } = backOffice.csOperator;

export const OPERATOR_SETTINGS_SCOPES = {
  categories: 'categories',
  settings: 'settings',
  form: 'form',
  import: 'import',
};

const operatorSettings = createCustomStore(({ runAsyncFlow }) => withScopes(OPERATOR_SETTINGS_SCOPES, ({
  mutations: {
    clearErrors,
    setError(state, payload) {
      toastError.bind(this)(
        state,
        payload,
        [OPERATOR_SETTINGS_SCOPES.categories, OPERATOR_SETTINGS_SCOPES.settings, OPERATOR_SETTINGS_SCOPES.form, OPERATOR_SETTINGS_SCOPES.import],
      );
    },
  },
  actions: {
    async getOperatorConfigSettingsByCategory({ commit }, { operatorId, category }) {
      await runAsyncFlow(commit, {
        request: getOperatorConfigSettingsByCategory,
        params: [operatorId, category],
        scope: OPERATOR_SETTINGS_SCOPES.settings,
      });
    },
    async getOperatorConfigCategories({ commit }) {
      await runAsyncFlow(commit, {
        request: getOperatorConfigCategories,
        params: [],
        scope: OPERATOR_SETTINGS_SCOPES.categories,
      });
    },
    async putOperatorConfigValues({ commit }, data) {
      await runAsyncFlow(commit, {
        request: putOperatorConfigValues,
        params: [{ values: data }],
        scope: OPERATOR_SETTINGS_SCOPES.form,
      });
    },
    async postUploadOperatorFile({ commit }, operatorFile) {
      await runAsyncFlow(commit, {
        request: postImportOperator,
        params: [{ operatorFile }],
        scope: OPERATOR_SETTINGS_SCOPES.import,
      });
    },
  },
})));

export default operatorSettings;
