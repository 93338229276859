import includes from 'lodash/includes';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors } from '@/utils';
import { setError as globalSetError } from '@/store/setError';

export const LEGAL_DOCUMENTS_SCOPES = {
  legalDocument: 'legalDocument',
  form: 'form',
  deleteLegalDocument: 'deleteLegalDocument',
  publishDocument: 'publishDocument',
};

const legalDocuments = createCustomStore(({ runAsyncFlow }) => withScopes(LEGAL_DOCUMENTS_SCOPES, ({
  state: {
    [LEGAL_DOCUMENTS_SCOPES.form]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;

      const scopesToShowGlobalErrors = [
        LEGAL_DOCUMENTS_SCOPES.publishDocument,
        LEGAL_DOCUMENTS_SCOPES.deleteLegalDocument,
      ];

      if (includes(scopesToShowGlobalErrors, scope)) {
        globalSetError.bind(this)(state, payload);
      } else {
        state[scope].error = value;
      }
    },
    clearErrors,
  },
  actions: {
    async getLegalDocument({ commit }, legalDocumentUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.legalDocuments.getLegalDocument,
        params: [legalDocumentUuid],
        scope: LEGAL_DOCUMENTS_SCOPES.legalDocument,
      });
    },
    async postLegalDocument({ commit }, legalDocumentData) {
      await runAsyncFlow(commit, {
        request: backOffice.legalDocuments.postLegalDocument,
        params: [legalDocumentData],
        scope: LEGAL_DOCUMENTS_SCOPES.form,
      });
    },
    async putLegalDocument({ commit }, { legalDocumentId, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.legalDocuments.putLegalDocument,
        params: [legalDocumentId, data],
        scope: LEGAL_DOCUMENTS_SCOPES.form,
      });
    },
    async putPublishLegalDocument({ commit }, { legalDocumentId, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.legalDocuments.putPublishLegalDocument,
        params: [legalDocumentId, data],
        scope: LEGAL_DOCUMENTS_SCOPES.publishDocument,
      });
    },
    async deleteLegalDocument({ commit }, legalDocumentId) {
      await runAsyncFlow(commit, {
        request: backOffice.legalDocuments.deleteLegalDocument,
        params: [legalDocumentId],
        scope: LEGAL_DOCUMENTS_SCOPES.deleteLegalDocument,
      });
    },
  },
})));

export default legalDocuments;
