import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

const { postUserPayout, postCompanyPayout } = backOffice.payouts;

const { postUserPayoutPaymentMethod, postCompanyPayoutPaymentMethod } = backOffice.paymentMethods;

const { getMedia } = backOffice.paymentMedia;

export const scopes = {
  paymentMethod: 'paymentMethod',
  newPayout: 'newPayout',
  media: 'media',
};

const payoutsModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  actions: {
    getUserPayoutPaymentMethod({ commit }, clientUuid) {
      const { getUserPayoutPaymentMethod } = backOffice.paymentMethods;
      runAsyncFlow(commit, {
        scope: scopes.paymentMethod,
        request: getUserPayoutPaymentMethod,
        params: [clientUuid],
      });
    },
    async postUserPayoutPaymentMethod({ commit }, { uuid, payoutPaymentMethod }) {
      await runAsyncFlow(commit, {
        scope: scopes.paymentMethod,
        request: postUserPayoutPaymentMethod,
        params: [uuid, payoutPaymentMethod],
      });
    },
    async postCompanyPayoutPaymentMethod({ commit }, { uuid, payoutPaymentMethod }) {
      await runAsyncFlow(commit, {
        scope: scopes.newPayout,
        request: postCompanyPayoutPaymentMethod,
        params: [uuid, payoutPaymentMethod],
      });
    },
    // TODO: Refactor code in PayoutFormComponent.vue with watchers
    // to avoid the need of async/await. Example can be
    // found on PayoutAccountFormComponent.vue
    async postUserPayout({ commit }, { uuid, request }) {
      await runAsyncFlow(commit, {
        scope: scopes.newPayout,
        request: postUserPayout,
        params: [uuid, request],
      });
    },
    // TODO: Refactor code in PayoutFormComponent.vue with watchers
    // to avoid the need of async/await. Example can be
    // found on PayoutAccountFormComponent.vue
    async postCompanyPayout({ commit }, { uuid, request }) {
      await runAsyncFlow(commit, {
        request: postCompanyPayout,
        params: [uuid, request],
      });
    },
    // TODO: Refactor code in PayoutFormComponent.vue with watchers
    // to avoid the need of async/await. Example can be
    // found on PayoutAccountFormComponent.vue
    async getMedia({ commit }, uuid) {
      await runAsyncFlow(commit, {
        scope: scopes.media,
        request: getMedia,
        params: [uuid],
      });
    },
  },
})));

export default payoutsModel;
